import React from 'react'
import PropTypes from 'prop-types'
//import { DiscussionEmbed } from 'disqus-react'
import Layout from './layout'
import { Link } from 'gatsby'

const Blogpost = props => {
  const { children, disqusTitle, disqusIdentifier, disqusUrl } = props
  if (!disqusTitle) {
    throw new Error('No Disqus title')
  }
  if (!disqusIdentifier) {
    throw new Error('No Disqus identifier')
  }
  if (!disqusUrl) {
    throw new Error('No Disqus url')
  }
  return (
    <Layout {...props}>
      <main>
        <article>
          {children}
        </article>
      </main>
      <Link to="/">Back to index</Link>
    </Layout>
  )
}

Blogpost.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Blogpost
